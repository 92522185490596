import moment from 'moment'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import Popup from 'reactjs-popup'
import { useStores } from '../utils/stores'
import CoachCancelQuestionView from './coach-cancel-question-view'
import ProfilePopPage from './profile.popup'
import { RescheduleSessions } from './Reschedule'
import { Booking } from './types/Booking'

const ListSessions = ({
    session,
    onSelectSession,
    startSession,
}: {
    session: Booking
    onSelectSession: any
    startSession: any
}) => {
    const naviagte = useNavigate()
    const { coach } = useStores()
    const [openProfile, setOpenProfile] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const [isOpenReschedule, setOpenReschedule] = useState(false)
    const [openCancelQuestion, setOpenCancelQuestion] = useState(false)

    const goToChat = () => {
        naviagte('/home/chat?id=' + session.fromCoachee._id)
    }

    const cancelBooking = useCallback(() => {
        coach.cancelBooking(session).subscribe()
        setOpenCancel(false)
    }, [])

    const isSessionActive = useCallback(
        (sessionDate: string | Date) => {
            return (
                new Date(new Date(sessionDate).getTime() - 60000 * 5) <
                new Date()
            )
        },
        [session],
    )

    const bookingDate = new Date(session.date)
    const now = new Date()
    const less_24hours =
        bookingDate.getTime() - now.getTime() < 24 * 60 * 60 * 1000

    const handleCancel = () => {
        if (less_24hours) {
            setOpenCancelQuestion(true)
        } else {
            setOpenCancel(true)
        }
    }

    return (
        <li className={'list-object session-object'}>
            <div>
                <div className="flex">
                    <img
                        className="pointer thumbnail m-3"
                        src={session.fromCoachee.image}
                        alt="pic"
                        onClick={() => setOpenProfile(true)}
                    />
                    <article className="media-body mt-1">
                        <div className="pt-3">
                            {session.fromCoachee.fullName}
                        </div>
                        <ProfilePopPage
                            coachee={session.fromCoachee}
                            openProfile={openProfile}
                            setOpenProfile={setOpenProfile}
                        />
                        <i
                            className="fa fa-angle-right pointer"
                            onClick={() => {
                                onSelectSession(session)
                            }}
                        ></i>
                    </article>
                </div>
                <div className="clearfix time-slot d-flex">
                    <span className="text-left">
                        {session.isVideoSession && (
                            <i className="fa session-start-video-icon"></i>
                        )}
                        {!session.isVideoSession && (
                            <i className="fa session-start-call-icon"></i>
                        )}
                        {moment(new Date(session.date)).format(
                            'DD MMMM YYYY, HH:mm',
                        )}
                    </span>
                    <span className="inline-flex absolute right-0 pr-[10px] pt-[9px] border-t-1 border-t-[#e8ecef]">
                        <img
                            className="clock-icon mt-[5px] "
                            src="/assets/images/clock-icon.png"
                            height="20px"
                        />
                        {session.duration} min
                    </span>
                </div>
                {session.participants && session.participants.length > 0 && (
                    <div className="clearfix time-slot d-flex">
                        <span className="inline-flex right-0 pr-[10px] pt-[9px] border-t-1 border-t-[#e8ecef]">
                            {session.participants
                                .map((participant) => participant.name)
                                .join(',')}{' '}
                            has been invited.
                        </span>
                    </div>
                )}
                {isSessionActive(session.date) ? (
                    <div className="btn-group d-flex">
                        <button
                            type="button"
                            className="btn blue"
                            onClick={() => startSession(session)}
                        >
                            {session.isVideoSession && (
                                <i className="fa session-start-video-icon"></i>
                            )}
                            {!session.isVideoSession && (
                                <i className="fa session-start-call-icon"></i>
                            )}
                            Start session
                        </button>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => setOpenCancelQuestion(true)}
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <div className="btn-group d-flex">
                        <button
                            type="button"
                            className="btn inline-flex"
                            onClick={goToChat}
                        >
                            <span className="m-auto inline-flex">
                                <img
                                    className="w-4 h-4 mr-[5px]"
                                    src="/assets/images/chat-bubble-black.png"
                                />
                                Chat
                            </span>
                        </button>
                        <button
                            className="btn"
                            onClick={() => setOpenReschedule(true)}
                        >
                            Reschedule
                        </button>

                        <RescheduleSessions
                            isOpen={isOpenReschedule}
                            setIsOpen={setOpenReschedule}
                            date={session.date}
                            _id={session._id}
                            participants={session.participants}
                        />
                        <button
                            type="button"
                            className="btn"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                )}
                <CoachCancelQuestionView
                    mainTitle={'Cancel session'}
                    open={openCancelQuestion}
                    close={setOpenCancelQuestion}
                    session={session}
                    type="cancel"
                />
                <Popup
                    open={openCancel}
                    onClose={() => setOpenCancel(false)}
                    className="alertBox"
                    modal={true}
                    nested
                >
                    <div>
                        <div>Are you sure you wish to cancel this session?</div>
                        <button
                            className="relative btn btn-light btn-bolck"
                            type="button"
                            onClick={cancelBooking}
                        >
                            Yes
                        </button>
                        <button
                            className="relative btn btn-light btn-bolck"
                            type="button"
                            onClick={() => setOpenCancel(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </Popup>
            </div>
        </li>
    )
}

export { ListSessions }
