import React from 'react'
import Autocompletemulti from '../_components/Autocompletemulti'
import Autocomplete from '../_components/Autocomplete'
function CoachingExp(props: any) {
    const { values, handleChange, setFieldValue } = props
    const { errors } = props
    const { touched } = props
    return (
        <>
            {' '}
            <h5 className="text-center">Coaching experience</h5>
            <div className="form-group">
                <label htmlFor="bio">Bio (Will be visible to coachees)</label>
                <textarea
                    id="bio"
                    placeholder="Say something about yourself..."
                    value={values.bio}
                    onChange={handleChange}
                    className="light-textarea form-control"
                    rows={4}
                    maxLength={650}
                ></textarea>
                <span className="carslenth">
                    {values.bio?.length ? values.bio?.length : '0'}/500
                </span>
                {errors.bio && touched.bio && (
                    <div className="error">{errors.bio}</div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="yearprice">Year started coaching</label>
                <input
                    id="startCoachingYear"
                    placeholder="2000"
                    type="number"
                    value={values.startCoachingYear}
                    onChange={handleChange}
                    className="form-control half-number"
                />
                {errors.startCoachingYear && touched.startCoachingYear && (
                    <div className="error">{errors.startCoachingYear}</div>
                )}
            </div>
            <div className="form-group">
                <label>Areas of coaching competencies</label>
                <Autocompletemulti
                    options={props?.statics.competencies}
                    selected={values.areasCompetencies}
                    onSelectCallback={(v: any) => {
                        setFieldValue('areasCompetencies', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.areasCompetencies && touched.areasCompetencies && (
                    <div className="error">{errors.areasCompetencies}</div>
                )}
            </div>
            <div className="form-group">
                <label>Coaching Specialties - Topics</label>
                <Autocompletemulti
                    options={props?.statics.coachingSpecialitiesTopics}
                    selected={values.coachingSpecialitiesTopics}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingSpecialitiesTopics', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.coachingSpecialitiesTopics &&
                    touched.coachingSpecialitiesTopics && (
                        <div className="error">
                            {errors.coachingSpecialitiesTopics}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label>Coaching Specialties - Client</label>
                <Autocompletemulti
                    options={props?.statics.coachingSpecialitiesClient}
                    selected={values.coachingSpecialitiesClient}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingSpecialitiesClient', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.coachingSpecialitiesClient &&
                    touched.coachingSpecialitiesClient && (
                        <div className="error">
                            {errors.coachingSpecialitiesClient}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label>Team Coaching Experience</label>
                <Autocomplete
                    options={props?.statics.teamCoachingExperience}
                    selected={values.coachingExperience}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingExperience', v, false)
                    }}
                    placeholder="Select"
                />
                {errors.coachingExperience && touched.coachingExperience && (
                    <div className="error">{errors.coachingExperience}</div>
                )}
            </div>
            <div className="form-group">
                <label>Team Coaching Credential</label>
                <Autocomplete
                    options={props?.statics.teamCoachingCredential}
                    selected={values.coachingCredentials}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingCredentials', v, false)
                    }}
                    placeholder="Select"
                />
                {errors.coachingCredentials && touched.coachingCredentials && (
                    <div className="error">{errors.coachingCredentials}</div>
                )}
            </div>
            <div className="form-group">
                <label>Assessment Certifications</label>
                <Autocompletemulti
                    options={props?.statics.assessmentCertifications}
                    selected={values.coachingCertifications}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingCertifications', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.coachingCertifications &&
                    touched.coachingCertifications && (
                        <div className="error">
                            {errors.coachingCertifications}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label>Coach Mentoring/Supervision</label>
                <Autocompletemulti
                    options={props?.statics.coachingMentoring}
                    selected={values.coachingMentoring}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingMentoring', v, false)
                    }}
                    placeholder="Select"
                />
                {errors.coachingMentoring && touched.coachingMentoring && (
                    <div className="error">{errors.coachingMentoring}</div>
                )}
            </div>
            <div className="form-group">
                <label>Training Topics / Areas of Expertise</label>
                <Autocompletemulti
                    options={props?.statics.trainingTopics}
                    selected={values.trainingTopics}
                    onSelectCallback={(v: any) => {
                        setFieldValue('trainingTopics', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.trainingTopics && touched.trainingTopics && (
                    <div className="error">{errors.trainingTopics}</div>
                )}
            </div>
            <div className="form-group">
                <label>
                    Interested In Collaboration w/Sama (Training, Content,
                    Community)
                </label>
                <Autocomplete
                    options={['Yes', 'No', 'Maybe']}
                    selected={values.interestedInCollaboration}
                    onSelectCallback={(v: any) => {
                        setFieldValue('interestedInCollaboration', v, false)
                    }}
                    placeholder="Select"
                />

                {errors.interestedInCollaboration &&
                    touched.interestedInCollaboration && (
                        <div className="error">
                            {errors.interestedInCollaboration}
                        </div>
                    )}
            </div>
            {(values.interestedInCollaboration === 'Yes' ||
                values.interestedInCollaboration === 'Maybe') && (
                <div className="form-group">
                    <label>
                        Are there any specific things you would like to
                        collaborate on ?
                    </label>
                    <input
                        id="coachCollaborationAreaOfInterest"
                        placeholder="Type here..."
                        type="text"
                        value={values.coachCollaborationAreaOfInterest}
                        onChange={handleChange}
                        className="form-control"
                    />{' '}
                    {errors.coachCollaborationAreaOfInterest &&
                        touched.coachCollaborationAreaOfInterest && (
                            <div className="error">
                                {errors.coachCollaborationAreaOfInterest}
                            </div>
                        )}
                </div>
            )}
            <div className="form-group">
                <label>Coaching Accreditations/Certifications</label>
                <Autocompletemulti
                    options={props?.statics.accreditations}
                    selected={values.coachingAccreditations}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingAccreditations', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingAccreditations &&
                    touched.coachingAccreditations && (
                        <div className="error">
                            {errors.coachingAccreditations}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label>Memberships</label>
                <Autocompletemulti
                    options={props?.statics.memberships}
                    selected={values.coachingMemberships}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingMemberships', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingMemberships && touched.coachingMemberships && (
                    <div className="error">{errors.coachingMemberships}</div>
                )}
            </div>
            <div className="form-group">
                <label>Industry experience (coaching in)</label>
                <Autocompletemulti
                    options={props?.statics.industries}
                    selected={values.coachingIndustries}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingIndustries', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingIndustries && touched.coachingIndustries && (
                    <div className="error">{errors.coachingIndustries}</div>
                )}
            </div>
            <div className="form-group">
                <label>Functional experience (coaching in)</label>
                <Autocompletemulti
                    options={props?.statics.functions}
                    selected={values.coachingFunctions}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingFunctions', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingFunctions && touched.coachingFunctions && (
                    <div className="error">{errors.coachingFunctions}</div>
                )}
            </div>
            <div className="clearfix">
                <label>Experience working with</label>
                <small className="pull-right">Number of years</small>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            C-Suite / Exectutives
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[0].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[0]?.years
                                ? values.experienceYearIn[0]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            Senior professionals
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[1].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[1]?.years
                                ? values.experienceYearIn[1]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">Managers</span>
                    </div>
                    <input
                        id="experienceYearIn[2].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[2]?.years
                                ? values.experienceYearIn[2]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">Associates</span>
                    </div>
                    <input
                        id="experienceYearIn[3].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[3]?.years
                                ? values.experienceYearIn[3]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon mb-5">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            Operations and Administration
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[4].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[4]?.years
                                ? values.experienceYearIn[4]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
        </>
    )
}
export { CoachingExp }
