import moment from 'moment'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { getCoachImage } from '../../_services/coach-image'
import { utilsService } from '../../_services/utils.service'
import { CalendarManager } from './calendar-sync/calendar-manager'

const ProfilePage = (props: any) => {
    const { user } = props.coach || {}
    const navigate = useNavigate()
    const imgSrc = getCoachImage()

    const gotoDetails = (count: any) => {
        sessionStorage.setItem('count', count)
        navigate('/details?fromProfile=' + count)
    }

    return (
        <div className="height-vh bg-pink page-profile user-profile-info">
            <div className="wrapper-container">
                <div className="block">
                    <div className="form-group profile-img Aligner">
                        {!imgSrc && (
                            <span className="fa fa-user fa-user-circle-o large-icon"></span>
                        )}
                        {imgSrc && (
                            <img
                                className="profile-pic"
                                src={imgSrc}
                                alt="logo"
                            />
                        )}
                    </div>
                    <p className="text-center mt-2 pro-title">
                        {user?.firstName + ' ' + user?.lastName}
                    </p>
                    <h6 className="semi-underline mt-4 clearfix">
                        Personal information{' '}
                        <span
                            className="fa edit-pencil-icon pull-right"
                            onClick={() => gotoDetails(1)}
                        ></span>
                    </h6>
                    <table className="table-bordered">
                        <tbody>
                            <tr>
                                <td>Phone number</td>
                                <td>{user?.phoneNumber}</td>
                            </tr>
                            <tr>
                                <td>Date of birth</td>
                                <td>
                                    {moment(new Date(user?.birthday)).format(
                                        'DD MMM, YYYY',
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>{props?.coach?.gender}</td>
                            </tr>
                            <tr>
                                <td>Country</td>
                                <td>{props?.statics?.selectedCountry}</td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>{user?.address.city}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Native language(s) <br />
                                    {utilsService.setArrayString(
                                        props?.statics?.selectedLangs,
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Coaching language(s) <br />
                                    {utilsService.setArrayString(
                                        props?.statics?.selectedCoachLangs,
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Highest education level </td>
                                <td>{props?.statics?.highestEducationLevel}</td>
                            </tr>
                            <tr>
                                <td>Maximum availability per week</td>
                                <td>{props?.coach?.maximumHourPerWeek}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 className="semi-underline mt-4 clearfix">
                        Coaching experience{' '}
                        <span
                            className="fa edit-pencil-icon pull-right"
                            onClick={() => gotoDetails(2)}
                        ></span>
                    </h6>
                    <table className="table-bordered">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    Biography <br />
                                    {props?.coach?.bio}
                                </td>
                            </tr>
                            <tr>
                                <td>Year started coaching</td>
                                <td>{props?.coach?.startCoachingYear}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Areas of coaching competencies <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics?.areasCompetencies,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Coaching Specialties - Topics <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.coachingSpecialityTopics,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Coaching Specialties - Client <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.coachingSpecialitiesClients,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Team Coaching Experience <br />
                                    <span>
                                        {props?.statics?.coachingExperience}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Team Coaching Credential <br />
                                    <span>
                                        {props?.statics?.coachingCredentials}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Assessment Certifications <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.coachingCertifications,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Coach Mentoring/Supervision <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics?.coachingMentorings,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Training Topics / Areas of Expertise <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics?.coachTrainingTopics,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Interested In Collaboration w/Sama
                                    (Training, Content, Community) <br />
                                    <span>
                                        {
                                            props?.statics
                                                ?.interestedInCollaboration
                                        }
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Coaching accreditations/certifications{' '}
                                    <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.coachingAccreditations,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Memberships <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics?.coachingMemberships,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Industry experience (coaching in) <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.experienceIndustriesCoaching,
                                        )}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Functional experience (coaching in) <br />
                                    <span>
                                        {utilsService.setArrayString(
                                            props?.statics
                                                ?.experienceFunctionsCoaching,
                                        )}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="clearfix">
                        <h6>Experience working with</h6>
                        <small className="pull-right">Number of years</small>
                    </div>
                    <div className="form-group left-addon">
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    C-Suite / Executive
                                </span>
                            </div>
                            <input
                                id="experienceYearIn[0].years"
                                placeholder="0"
                                type="number"
                                value={
                                    props.coach.experienceYearIn[0]?.years
                                        ? props.coach.experienceYearIn[0]?.years
                                        : '0'
                                }
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="form-group left-addon">
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    Senior professionals
                                </span>
                            </div>
                            <input
                                id="experienceYearIn[1].years"
                                placeholder="0"
                                type="number"
                                value={
                                    props.coach.experienceYearIn[1]?.years
                                        ? props.coach.experienceYearIn[1]?.years
                                        : '0'
                                }
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="form-group left-addon">
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    Managers
                                </span>
                            </div>
                            <input
                                id="experienceYearIn[2].years"
                                placeholder="0"
                                type="number"
                                value={
                                    props.coach.experienceYearIn[2]?.years
                                        ? props.coach.experienceYearIn[2]?.years
                                        : '0'
                                }
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="form-group left-addon">
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    Associates
                                </span>
                            </div>
                            <input
                                id="experienceYearIn[3].years"
                                placeholder="0"
                                type="number"
                                value={
                                    props.coach.experienceYearIn[3]?.years
                                        ? props.coach.experienceYearIn[3]?.years
                                        : '0'
                                }
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="form-group left-addon mb-5">
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    Operations and Administration
                                </span>
                            </div>
                            <input
                                id="experienceYearIn[4].years"
                                placeholder="0"
                                type="number"
                                value={
                                    props.coach.experienceYearIn[4]?.years
                                        ? props.coach.experienceYearIn[4]?.years
                                        : '0'
                                }
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <h6 className="semi-underline mt-4 clearfix">
                        Professional experience{' '}
                        <span
                            className="fa edit-pencil-icon pull-right"
                            onClick={() => gotoDetails(3)}
                        ></span>
                    </h6>
                    <table className="table-bordered">
                        <tbody>
                            <tr>
                                <td>Total years of professional experience</td>
                                <td>{props?.coach?.experienceTotalYear}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Industry experience <br />
                                    {utilsService.setArrayString(
                                        props?.statics?.experienceIndustries,
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Functional experience <br />
                                    {utilsService.setArrayString(
                                        props?.statics?.experienceFunctions,
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Countries worked in <br />
                                    {utilsService.setArrayString(
                                        props?.statics?.experienceCountry,
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 className="semi-underline mt-4 clearfix">
                        Calendar Sync
                    </h6>
                    <CalendarManager />
                    <h6 className="semi-underline mt-4 clearfix">Account</h6>
                    <a href="/change">
                        <i className="icon-off"></i> Change password
                    </a>
                    <br />
                    <a href="/login">
                        <i className="icon-off"></i> Logout
                    </a>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
    statics: state.statics,
})

export default connect(mapStateToProps)(ProfilePage)
