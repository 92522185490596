import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react'
import { ListSessions } from './sessions.list'
import NotesTaskGoals from './notesTasksGoals.tabs'

import { Booking } from './types/Booking'
import { useStores } from '../utils/stores'
import { Subscription } from 'rxjs'
import { cancelSubscriptions } from '../utils/misc/subscriptions'

import VideoPopup from './video-popup'
import { RoomSettings } from '../VideoChat/room-settings'
import settingsIcon from '../assets/icons/settings.svg'

export const Sessions: React.FC = observer(() => {
    const { coach } = useStores()
    const subscriptions: Subscription[] = []
    const [currentBooking, setCurrentBooking] = useState<Booking>()

    const [isVideoPop, onCloseVideoPop] = useState<boolean>(false)
    const [settingsOpen, setSettingsOpen] = useState(false)

    useEffect(() => {
        populateBookings()
    }, [])

    const populateBookings = useCallback(() => {
        const subscription = coach.getBookings().subscribe({
            next(response) {
                if (response.data) {
                    setCurrentBooking(coach.bookings[0])
                    onCloseVideoPop(true)
                }
            },
        })

        subscriptions.push(subscription)
        setCurrentBooking(coach.bookings[0])

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [coach.bookings])

    function onSelectSession(item: Booking) {
        setCurrentBooking(item)
    }

    function startSession(booking: Booking) {
        if (!booking.participants || booking.participants.length < 1) {
            window.open('/home/?bookingId=' + booking._id, '_blank')
        } else {
            coach.getSessionUrl(booking._id).subscribe({
                next(response) {
                    if (response.data?.url) {
                        window.open(response.data?.url, '_blank')
                    } else {
                        populateBookings()
                    }
                },
            })
        }
    }

    const listItems = useMemo(
        () =>
            coach.bookings.map((item, i) => {
                return (
                    <ListSessions
                        key={i}
                        startSession={startSession}
                        onSelectSession={onSelectSession}
                        session={item}
                    />
                )
            }),
        [coach.bookings],
    )

    return (
        <>
            <RoomSettings open={settingsOpen} setOpen={setSettingsOpen} />
            {isVideoPop && <VideoPopup onCloseVideoPop={onCloseVideoPop} />}
            <section className="row">
                {listItems.length > 0 && (
                    <div className="col-md-5">
                        <div className="wrapper-container">
                            <div className="session-list">
                                <span className="inline-flex pb-3 w-full">
                                    <h6 className="pointer w-[97%] ">
                                        Upcoming sessions
                                    </h6>
                                    <img
                                        className="pointer w-7"
                                        onClick={() => setSettingsOpen(true)}
                                        src={settingsIcon}
                                    />
                                </span>
                                <ul className="liste-group bookings">
                                    {listItems}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {listItems.length !== 0 && (
                    <div className="col-md-7">
                        <NotesTaskGoals {...currentBooking} />
                    </div>
                )}

                {listItems && listItems.length === 0 && (
                    <div className="col-md-12">
                        <div className="chanels-container">
                            <span className="inline-flex pb-3 w-full">
                                <h6 className="pointer w-[97%] ">
                                    Upcoming sessions
                                </h6>
                                <img
                                    className="pointer w-7"
                                    onClick={() => setSettingsOpen(true)}
                                    src={settingsIcon}
                                />
                            </span>
                            <div
                                style={{
                                    position: 'relative',
                                    minHeight: '60vh',
                                    width: '100%',
                                    height: '80%',
                                    paddingTop: '10%',
                                }}
                            >
                                <div
                                    style={{
                                        margin: 'auto',
                                        top: '50%',
                                        width: '30%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <p style={{ color: '#CFCFCF' }}>
                                        No sessions have are booked
                                    </p>
                                    <p>
                                        Session details will appear here when
                                        your coachee(s) book a sessions
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
})
