import { formatDistance } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { CoachNotification } from '../models/response/coach-notification'
import { useStores } from '../utils/stores'
import { useNavigate } from 'react-router-dom'

const Notifications = ({
    showAllButton = false,
    limit = 150,
}: {
    showAllButton?: boolean
    limit?: number
}) => {
    const navigate = useNavigate()
    const { coach } = useStores()

    useEffect(() => {
        coach.getCoachNotifications().subscribe()
    }, [])

    const handleRead = useCallback((notification: CoachNotification) => {
        if (notification.status !== 'read') {
            coach.updateCoachNotifications(notification).subscribe()
        }
    }, [])

    const markAllRead = useCallback(() => {
        coach.markAllReadCoachNotifications().subscribe()
    }, [])

    return (
        <div>
            <div className="flex justify-center">
                <div className="bg-[#fff] rounded-[8px] w-[700px] border-1 pb-3 border-[#cdcdcd] shadow-sm">
                    <div>
                        <div className="text-[24px] text-blue mt-2 pl-5 pt-2">
                            Notifications
                            <span
                                className=" font-bold justify-center ml-3 self-center text-[14px] hover:cursor-pointer underline text-[#004E72]"
                                onClick={() => markAllRead()}
                            >
                                Mark all as read
                            </span>
                        </div>

                        <div className="my-3 text-[14px]">
                            {coach.coachNotifications &&
                            coach.coachNotifications.length > 0 ? (
                                <div>
                                    {coach.coachNotifications
                                        ?.slice(0, limit)
                                        .map((notification) => {
                                            return (
                                                <div
                                                    key={notification._id}
                                                    className={`flex hover:bg-[#cde7fb] ${
                                                        notification.status ===
                                                        'read'
                                                            ? 'bg-[#fff]'
                                                            : 'bg-[#fffaf5]'
                                                    } py-3 pl-5 hover:cursor-pointer items-center border-b-[1px] border-[#cdcdcd] first:border-t-[1px]`}
                                                    onClick={() =>
                                                        handleRead(notification)
                                                    }
                                                >
                                                    <div className="flex rounded-full h-8 w-8 bg-[#004E72] justify-center items-center mr-3">
                                                        <div
                                                            className="font-bold text-white
                                                    "
                                                        >
                                                            {notification.user.firstName
                                                                .charAt(0)
                                                                .toUpperCase()}
                                                            {notification.user.lastName
                                                                .charAt(0)
                                                                .toUpperCase()}
                                                        </div>
                                                    </div>
                                                    <div className="leading-4">
                                                        <div className="flex">
                                                            <div className="font-bold">
                                                                {
                                                                    notification
                                                                        .user
                                                                        .firstName
                                                                }{' '}
                                                                {
                                                                    notification
                                                                        .user
                                                                        .lastName
                                                                }
                                                            </div>
                                                            <div className="ml-1">
                                                                {
                                                                    notification.content
                                                                }
                                                            </div>
                                                        </div>
                                                        {notification.more && (
                                                            <a
                                                                href={
                                                                    notification
                                                                        .more
                                                                        .action
                                                                }
                                                                className="text-sm text-[#91919c]"
                                                            >
                                                                {
                                                                    notification
                                                                        .more
                                                                        .content
                                                                }
                                                            </a>
                                                        )}
                                                        <div className="text-sm text-[#91919c]">
                                                            {formatDistance(
                                                                new Date(
                                                                    notification._createdAt,
                                                                ),
                                                                new Date(),
                                                                {
                                                                    addSuffix:
                                                                        true,
                                                                },
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            ) : (
                                <div className="flex ml-6 self-center text-[16px]">
                                    <>You have no notifications.</>
                                </div>
                            )}
                        </div>
                        {showAllButton && (
                            <div
                                className="flex font-bold justify-center ml-3 self-center text-[16px] hover:cursor-pointer underline text-[#004E72]"
                                onClick={() => navigate('/home/notifications')}
                            >
                                See all notifications
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications
