import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class Autocomplete extends Component {
    static propTypes = {
        options: PropTypes.instanceOf(Array).isRequired,
        selected: PropTypes.any,
        onSelectCallback: PropTypes.any,
        placeholder: PropTypes.any,
    }

    toggleContainer: any
    constructor(props: any) {
        super(props)
        this.toggleContainer = React.createRef()
        this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    }
    state = {
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: (this.props as any).selected,
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler)
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler)
    }
    onClickOutsideHandler(event: any) {
        if (
            this.state.showOptions &&
            !this.toggleContainer.current.contains(event.target)
        ) {
            this.setState((state: any) => ({
                activeOption: 0,
                filteredOptions: [],
                showOptions: !state.showOptions,
            }))
        }
    }
    onChange = (e: any) => {
        const { options } = this.props as any
        const userInput = e.currentTarget.value

        const filteredOptions = options.filter(
            (optionName: any) =>
                optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
        )

        this.setState({
            activeOption: 0,
            filteredOptions,
            showOptions: userInput ? true : false,
            userInput: e.currentTarget.value,
        })
    }

    saveOption = (text: any) => {
        if ('onSelectCallback' in this.props) {
            ;(this.props as any).onSelectCallback(text)
        }

        this.setState({
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: text,
        })
    }

    onClick = (e: any) => {
        this.saveOption(e.currentTarget.innerText)
    }
    onDropdown = (e: any) => {
        this.setState((state: any, props: any) => ({
            activeOption: 0,
            filteredOptions: props.options,
            showOptions: !state.showOptions,
        }))
    }
    onKeyDown = (e: any) => {
        const { activeOption, filteredOptions } = this.state

        if (e.keyCode === 13) {
            this.saveOption(filteredOptions[activeOption])
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return
            }
            this.setState({ activeOption: activeOption - 1 })
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                return
            }
            this.setState({ activeOption: activeOption + 1 })
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            onDropdown,

            state: { activeOption, filteredOptions, showOptions, userInput },
        } = this
        let optionList
        if (showOptions) {
            if (filteredOptions.length) {
                optionList = (
                    <ul className="options">
                        {filteredOptions.map((optionName, index) => {
                            let className
                            if (index === activeOption) {
                                className = 'option-active'
                            }
                            return (
                                <li
                                    className={className}
                                    key={optionName}
                                    onClick={onClick}
                                >
                                    {optionName}
                                </li>
                            )
                        })}
                    </ul>
                )
            } else {
                optionList = (
                    <div className="no-options">
                        <em>No Option!</em>
                    </div>
                )
            }
        }
        return (
            <div
                ref={this.toggleContainer}
                className={
                    showOptions
                        ? 'filter-dropdown-container open'
                        : 'filter-dropdown-container'
                }
            >
                <div className="filter-dropdown">
                    {(this.props as any).disabled && (
                        <div>
                            {(this.props as any).selected
                                ? (this.props as any).selected
                                      ?.charAt(0)
                                      .toUpperCase() +
                                  (this.props as any).selected?.slice(1)
                                : ''}
                        </div>
                    )}
                    {!(this.props as any).disabled && (
                        <>
                            <input
                                type="text"
                                className="form-control"
                                autoComplete="chrome-off"
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                value={userInput ?? ''}
                                placeholder={(this.props as any).placeholder}
                            />
                            <button
                                className="down-carret"
                                type="button"
                                onClick={onDropdown}
                            >
                                <span className="arrow down"></span>
                            </button>
                        </>
                    )}
                </div>
                {optionList}
            </div>
        )
    }
}

export default Autocomplete
